import Icons from "./icons";
import { PERMISSION_CODES, getPermissions } from "./permissions";
import {
  createOrder,
  get,
  updateOrder,
  updatePayment,
  updatePropertyDetails,
  updateStatus,
  createNotes,
  notesUpdate,
  getList,
} from "../services/api";

const getSidebarLinks1 = (PERMISSIONS) => {
  const LINKS = [{ icon: Icons.dashboard, label: "Dashboard", pathname: "/" }];
  if (
    getPermissions(PERMISSIONS, "tenant").includes(PERMISSION_CODES.tenant[0])
  ) {
    LINKS.push({
      icon: Icons.supplier,
      label: "Tenants",
      pathname: "/tenants",
    });
  }
  if (
    getPermissions(PERMISSIONS, "invoiceList").includes(
      PERMISSION_CODES.invoiceList[0]
    )
  ) {
    LINKS.push({
      icon: Icons.invoiceList,
      label: "Invoices",
      pathname: "/invoices",
    });
  }
  if (
    getPermissions(PERMISSIONS, "tenantPayment").includes(
      PERMISSION_CODES.tenantPayment[0]
    )
  ) {
    LINKS.push({
      icon: Icons.supplierPayment,
      label: "Tenants Payments",
      pathname: "/tenants/payments",
    });
  }
  if (
    getPermissions(PERMISSIONS, "property").includes(
      PERMISSION_CODES.property[0]
    )
  ) {
    LINKS.push({
      icon: Icons.dashboard,
      label: "Properties",
      pathname: "/properties",
    });
  }
  if (
    getPermissions(PERMISSIONS, "leases").includes(PERMISSION_CODES.leases[0])
  ) {
    LINKS.push({ icon: Icons.home, label: "Lease", pathname: "/leases" });
  }
  if (
    getPermissions(PERMISSIONS, "superuser").includes(
      PERMISSION_CODES.superuser[0]
    )
  ) {
    LINKS.push({
      icon: Icons.admins,
      label: "Superuser",
      pathname: "/superuser",
    });
  }
  if (
    getPermissions(PERMISSIONS, "banner").includes(PERMISSION_CODES.banner[0])
  ) {
    LINKS.push({ icon: Icons.banner, label: "Banners", pathname: "/banners" });
  }
  return LINKS;
};

// const getSidebarLinks2 = PERMISSIONS => {
//   const LINKS = []
//   if (getPermissions(PERMISSIONS, 'category').includes(PERMISSION_CODES.category[0])) {
//     LINKS.push({ icon: Icons.categories, label: 'Categories', pathname: '/categories' })
//   }
//   if (getPermissions(PERMISSIONS, 'subcategory').includes(PERMISSION_CODES.subcategory[0])) {
//     LINKS.push({ icon: Icons.subcategories, label: 'Subcategories', pathname: '/subcategories' })
//   }
//   if (getPermissions(PERMISSIONS, 'subSubcategory').includes(PERMISSION_CODES.subSubcategory[0])) {
//     LINKS.push({ icon: Icons.subSubcategories, label: 'Sub-Subcategories', pathname: '/sub-subcategories' })
//   }
//   return LINKS
// }

// const getSidebarLinks3 = PERMISSIONS => {
//   const LINKS = []
//   if (getPermissions(PERMISSIONS, 'collection').includes(PERMISSION_CODES.collection[0])) {
//     LINKS.push({ icon: Icons.collections, label: 'Collections', pathname: '/collections' })
//   }
//   if (getPermissions(PERMISSIONS, 'product').includes(PERMISSION_CODES.product[0])) {
//     LINKS.push({ icon: Icons.products, label: 'Products', pathname: '/products' })
//   }
//   if (getPermissions(PERMISSIONS, 'advertisement').includes(PERMISSION_CODES.advertisement[0])) {
//     LINKS.push({ icon: Icons.ads, label: 'Advertisements', pathname: '/advertisements' })
//     LINKS.push({ icon: Icons.banner, label: 'Advertisement Banners', pathname: '/advertisement-banners' })
//   }
//   return LINKS
// }

// const getSidebarLinks4 = PERMISSIONS => {
//   const LINKS = []
//   if (getPermissions(PERMISSIONS, 'order').includes(PERMISSION_CODES.order[0])) {
//     LINKS.push({ icon: Icons.orders, label: 'Orders', pathname: '/orders' })
//   }
//   return LINKS
// }

// const getSidebarLinks5 = PERMISSIONS => {
//   const LINKS = []
//   if (getPermissions(PERMISSIONS, 'order').includes(PERMISSION_CODES.order[0])) {
//     LINKS.push({ icon: Icons.resellerPayment, label: 'Reseller Payments', pathname: '/reseller-payments' })
//   }
//   if (getPermissions(PERMISSIONS, 'order').includes(PERMISSION_CODES.order[0])) {
//     LINKS.push({ icon: Icons.supplierPayment, label: 'Supplier Payments', pathname: '/supplier-payments' })
//   }
//   return LINKS
// }
// const getSidebarLinks6 = PERMISSIONS => {
//   const LINKS = []
//   if (getPermissions(PERMISSIONS, 'bonus').includes(PERMISSION_CODES.bonus[0])) {
//     LINKS.push({ icon: Icons.bonus, label: 'Bonus', pathname: '/bonus' })
//   }
//   return LINKS
// }
// const getSidebarLinks7 = PERMISSIONS => {
//   const LINKS = []
//   if (getPermissions(PERMISSIONS, 'coupon').includes(PERMISSION_CODES.coupon[0])) {
//     LINKS.push({ icon: Icons.coupon, label: 'Coupons', pathname: '/coupons' })
//   }
//   return LINKS
// }

export const getSidebarLinks = (PERMISSIONS) => [
  getSidebarLinks1(PERMISSIONS),
  // getSidebarLinks2(PERMISSIONS),
  // getSidebarLinks3(PERMISSIONS),
  // getSidebarLinks4(PERMISSIONS),
  // getSidebarLinks5(PERMISSIONS),
  // getSidebarLinks6(PERMISSIONS),
  // getSidebarLinks7(PERMISSIONS),
  // [
  //   { icon: Icons.dashboard, label: 'Dashboard', pathname: '/' },
  //   { icon: Icons.dashboard, label: 'Tenants', pathname: '/tenants' },
  //   { icon: Icons.dashboard, label: 'Tenants Payments', pathname: '/tenants/payments' },
  //   { icon: Icons.dashboard, label: 'Properties', pathname: '/properties' },

  // ],
];

const list = [];
// const getMakaniNumber = async() => {
//   try {
//     const result = await getList(['makaniNumber'])
//     for (let i = 0; i < result.data.list.length; i ++) {
//       if (/^\d+$/.test(result.data.list[i].makaniNumber)) {
//         list.push(result.data.list[i].makaniNumber)
//       }
//     }
//     return list
//   } catch (error) {
//     console.log(error);
//   }
// }
// getMakaniNumber()

export const makaniNumber = list;

export const getSidebarFooterLinks = (PERMISSIONS) => {
  const LINKS = [];
  if (
    getPermissions(PERMISSIONS, "superuser").includes(
      PERMISSION_CODES.superuser[0]
    )
  ) {
    // LINKS.push({ icon: Icons.admins, color: 'primary', label: 'Superusers', pathname: '/superusers' })
    LINKS.push({
      icon: Icons.admins,
      color: "primary",
      label: "Superusers",
      pathname: "/superuser",
    });
  }
  if (
    getPermissions(PERMISSIONS, "superuser").includes(
      PERMISSION_CODES.superuser[0]
    )
  ) {
    LINKS.push({
      icon: Icons.settings,
      label: "Settings",
      pathname: "/settings",
    });
  } else if (
    getPermissions(PERMISSIONS, "setting").includes(
      PERMISSION_CODES.settings[0]
    )
  ) {
    // LINKS.push({ icon: Icons.settings, label: 'Settings', pathname: '/settings' })
    LINKS.push({ icon: Icons.settings, label: "Settings", pathname: "/" });
  }
  return LINKS;
};

export const AGE_GROUPS = [
  { label: "18-25", value: "18-25" },
  { label: "26-35", value: "26-35" },
  { label: "35+", value: "35+" },
];

export const GENDERS = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

export const REST_FREQUENCY = [
  { label: "Yearly", value: "yearly" },
  { label: "Monthly", value: "monthly" },
  { label: "2 cheques", value: 2 },
  { label: "4 cheques", value: 4 },
  { label: "6 cheques", value: 6 },
];

export const NO_OF_INVOICES = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "4", value: 4 },
  { label: "6", value: 6 },
  { label: "12", value: 12 },
];

export const UAE_CITIES = [
  "Abu Dhabi",
  "Ajman",
  "Al Ain",
  "Dubai",
  "Fujairah",
  "Kalba",
  "Khor Fakkan",
  "Ras Al Khaimah",
  "Sharjah",
  "Um Al Quiwan",
];

export const ORDER_STATUSES = [
  "Created",
  "Accepted",
  "Rejected",
  "Ready to ship",
  //'Cancelled',
  "Shipped",
  "Out for delivery",
  "Delivered",
  //'Exchange requested',
  "Exchange accepted",
  "Exchange rejected",
  "Exchange initiated",
  "Exchange picked up",
  "Exchanged",
  //'Refund requested',
  //'Refund initiated',
  "Refund accepted",
  "Refund rejected",
  "Refund processing",
  "Refund processed",
  "Return initiated",
  "Return picked up",
  "Returned",
];

export const CATEGORY_STATUSES = ["Pending Approval", "Active", "Hidden"];
export const CATEGORY_STATUS_CHANGE_BUTTON_TEXTS = {
  "Pending Approval": "Approve",
  Active: "Hide",
  Hidden: "Show",
};

export const PROPERTY_STATUS = [
  { title: "Approved", value: "approved" },
  { title: "Approval Pending", value: "approval_pending" },
  { title: "Blocked", value: "blocked" },
];

export const getCategoryStatusToBeUpdated = (oldStatus) => {
  return {
    "Pending Approval": CATEGORY_STATUSES[1],
    Active: CATEGORY_STATUSES[2],
    Hidden: CATEGORY_STATUSES[1],
  }[oldStatus];
};

export const COLLECTION_STATUSES = ["Pending Approval", "Active", "Hidden"];
export const COLLECTION_STATUS_CHANGE_BUTTON_TEXTS = {
  "Pending Approval": "Approve",
  Active: "Hide",
  Hidden: "Show",
};
export const getCollectionStatusToBeUpdated = (oldStatus) => {
  return {
    "Pending Approval": CATEGORY_STATUSES[1],
    Active: CATEGORY_STATUSES[2],
    Hidden: CATEGORY_STATUSES[1],
  }[oldStatus];
};

export const PRODUCT_STATUSES = ["Pending Approval", "Active", "Hidden"];
export const PRODUCT_STATUS_CHANGE_BUTTON_TEXTS = {
  "Pending Approval": "Approve",
  Active: "Hide",
  Hidden: "Show",
};
export const getProductStatusToBeUpdated = (oldStatus) => {
  return {
    "Pending Approval": CATEGORY_STATUSES[1],
    Active: CATEGORY_STATUSES[2],
    Hidden: CATEGORY_STATUSES[1],
  }[oldStatus];
};

export const SUPERUSER_STATUSES = ["Active", "Blocked"];
export const SUPERUSER_STATUS_CHANGE_BUTTON_TEXTS = {
  Active: "Block",
  Blocked: "Unblock",
};
export const getSuperuserStatusToBeUpdated = (oldStatus) => {
  return {
    Active: SUPERUSER_STATUSES[1],
    Blocked: SUPERUSER_STATUSES[0],
  }[oldStatus];
};

export const RESELLER_STATUSES = ["Active", "Blocked"];
export const RESELLER_STATUS_CHANGE_BUTTON_TEXTS = {
  Active: "Block",
  Blocked: "Unblock",
};
export const getResellerStatusToBeUpdated = (oldStatus) => {
  return {
    Active: RESELLER_STATUSES[1],
    Blocked: RESELLER_STATUSES[0],
  }[oldStatus];
};

export const ADVERTISEMENT_STATUSES = ["Active", "Hidden"];
export const ADVERTISEMENT_STATUS_CHANGE_BUTTON_TEXTS = {
  Active: "Hide",
  Hidden: "Show",
};
export const getAdvertisementStatusToBeUpdated = (oldStatus) => {
  return {
    Active: ADVERTISEMENT_STATUSES[1],
    Hidden: ADVERTISEMENT_STATUSES[0],
  }[oldStatus];
};

export const UAE_AREAS = {
  [UAE_CITIES[0]]: [
    "Abu Dhabi Golf Club",
    "Abu Dhabi International Airport",
    "Abu Dhabi University",
    "Ad Gate City - Officer City",
    "AD police Headquarter",
    "Airport Garden",
    "Al Aman",
    "Al Baladiyah-Municipality - Al Dana E4_02",
    "Al Baladiyah-Municipality - Al Danah E11",
    "Al Bateen",
    "Al Bateen Airport",
    "Al Dhafrah",
    "Al Dhafrah E18-02",
    "Al Dhafrah E18-03",
    "Al Etihad",
    "Al Falah",
    "Al Forsan Village",
    "Al Ghazal Golf Club",
    "Al Hosn",
    "Al Jazira Club",
    "Al Karamah",
    "Al Khalidiyah",
    "Al Kheeran - Eastern Mangrove",
    "Al Khubeirah",
    "Al Maarid - Capital Center",
    "Al Mafraq",
    "Al Manaseer",
    "Al Manhal - Dayrt Al Meyah",
    "Al Maqta - Bain Al Jesrain",
    "Al Marina",
    "Al Markaziyah - Al Danah E1",
    "Al Markaziyah - Al Danah E10",
    "Al Markaziyah - Al Danah E3_01",
    "Al Markaziyah - Al Danah E3_02",
    "Al Markaziyah - Al Danah E5",
    "Al Markaziyah - Al Danah E6",
    "Al Markaziyah - Al Danah E7",
    "Al Markaziyah - Al Danah E8",
    "Al Markaziyah - Al Danah E9_01",
    "Al Markaziyah West",
    "Al Maryah Island",
    "Al Mina - AUH",
    "Al Moazaz",
    "Al Moazaz South",
    "Al Muntazah - AUH",
    "AL MUSALLA",
    "Al Mushrif",
    "Al Muzoon",
    "Al Nahda Al Askariya",
    "Al Nahyan",
    "Al Nahyan E25 - Al Mamoura",
    "Al Raha Beach",
    "Al Raha Gardens",
    "Al Rahba",
    "Al Ras Al Akhdar",
    "Al Rayhan",
    "Al Rayyana",
    "Al Reef",
    "Al Reef Villas",
    "Al Reem Island",
    "Al Rowdah",
    "Al Ruwais Housing Complex",
    "Al Ruwais Industrial Area",
    "Al Shaabiya Al Jadida - Bada Zayed",
    "Al Shahama Old",
    "Al Shamkha",
    "Al Shamkha East",
    "Al Shawamekh",
    "Al Tibbiya",
    "Al Wahdah",
    "Al Wathba Al Askariya",
    "Al Wathba North",
    "Al Wathba Park",
    "Al Wathba South",
    "Al Zaab",
    "Al Zafranah - Al Muroor",
    "Al Zahraa",
    "Armed Forces Officers Club",
    "Bainouna - Madinat Mohamed",
    "Baniyas East 1",
    "Baniyas East 10",
    "Baniyas East 11",
    "Baniyas East 13",
    "Baniyas East 2",
    "Baniyas East 3",
    "Baniyas East 4",
    "Baniyas East 5",
    "Baniyas East 6",
    "Baniyas East 7",
    "Baniyas East 8",
    "Baniyas East 9",
    "Baniyas North",
    "Baniyas West 1",
    "Baniyas West 2",
    "Baniyas West 3",
    "Baniyas West 4",
    "Bawabat Al Sharq - Al Ghabat",
    "Corniche Al Khalidiyah",
    "Corniche Road",
    "Defence Colony - Bada Zayed",
    "Embassies District",
    "Etihad Plaza - SE-45",
    "Golf Gardens",
    "Hills Abu Dhabi",
    "Hydra Village",
    "Icad 1",
    "Jebel Dhanna",
    "Jern Yafoor ",
    "Khalifa City A- SE-1",
    "Khalifa City A- SE-11",
    "Khalifa City A- SE-12",
    "Khalifa City A- SE-13",
    "Khalifa City A- SE-14",
    "Khalifa City A- SE-15",
    "Khalifa City A- SE-16",
    "Khalifa City A- SE-2",
    "Khalifa City A- SE-22",
    "Khalifa City A- SE-23",
    "Khalifa City A- SE-24",
    "Khalifa City A- SE-25",
    "Khalifa City A- SE-26",
    "Khalifa City A- SE-3",
    "Khalifa City A- SE-34",
    "Khalifa City A- SE-35",
    "Khalifa City A- SE-36",
    "Khalifa City A- SE-38",
    "Khalifa City A- SE-39",
    "Khalifa City A- SE-4",
    "Khalifa City A- SE-40",
    "Khalifa City A- SE-41",
    "Khalifa City A- SE-42",
    "Khalifa City A- SE-43",
    "Khalifa City A- SE-44",
    "Khalifa City A- SE-5",
    "Khalifa City A- SE-6",
    "Khalifa City A- SW-1",
    "Khalifa City A- SW-11",
    "Khalifa City A- SW-12",
    "Khalifa City A- SW-13",
    "Khalifa City A- SW-14",
    "Khalifa City A- SW-15",
    "Khalifa City A- SW-16",
    "Khalifa City A- SW-2",
    "Khalifa City A- SW-3",
    "Khalifa City A- SW-4",
    "Khalifa City A- SW-5",
    "Khalifa City A- SW-6",
    "Khalifa City B - Shakhbout City",
    "Khor Al Maqtaa",
    "Liwa Air Base",
    "Madinat Zayed - Al Danah E4_01",
    "Madinat Zayed Western Region - Bada Zayed",
    "Maharba - Al Bateen",
    "Mahwi",
    "Marabe Al Dhafra",
    "Marina Al Bateen",
    "Masdar City",
    "Mbz Etihad Accommodation",
    "Ministry Of Interior",
    "Mohamed Bin Zayed Z1",
    "Mohamed Bin Zayed Z10",
    "Mohamed Bin Zayed Z11",
    "Mohamed Bin Zayed Z12",
    "Mohamed Bin Zayed Z13",
    "Mohamed Bin Zayed Z14",
    "Mohamed Bin Zayed Z15",
    "Mohamed Bin Zayed Z16",
    "Mohamed Bin Zayed Z17",
    "Mohamed Bin Zayed Z18",
    "Mohamed Bin Zayed Z19",
    "Mohamed Bin Zayed Z2",
    "Mohamed Bin Zayed Z20",
    "Mohamed Bin Zayed Z21",
    "Mohamed Bin Zayed Z23",
    "Mohamed Bin Zayed Z24",
    "Mohamed Bin Zayed Z25",
    "Mohamed Bin Zayed Z26",
    "Mohamed Bin Zayed Z27",
    "Mohamed Bin Zayed Z28",
    "Mohamed Bin Zayed Z29",
    "Mohamed Bin Zayed Z3",
    "Mohamed Bin Zayed Z30",
    "Mohamed Bin Zayed Z31",
    "Mohamed Bin Zayed Z32",
    "Mohamed Bin Zayed Z33",
    "Mohamed Bin Zayed Z34",
    "Mohamed Bin Zayed Z35",
    "Mohamed Bin Zayed Z4",
    "Mohamed Bin Zayed Z5",
    "Mohamed Bin Zayed Z6",
    "Mohamed Bin Zayed Z7",
    "Mohamed Bin Zayed Z8",
    "Mohamed Bin Zayed Z9",
    "Motor World Automobiles",
    "Mussafah M1",
    "Mussafah M10",
    "Mussafah M11",
    "Mussafah M12",
    "Mussafah M13",
    "Mussafah M14",
    "Mussafah M15",
    "Mussafah M16",
    "Mussafah M17",
    "Mussafah M18",
    "Mussafah M19",
    "Mussafah M2",
    "Mussafah M20",
    "Mussafah M21",
    "Mussafah M22",
    "Mussafah M23",
    "Mussafah M24",
    "Mussafah M25",
    "Mussafah M26",
    "Mussafah M28",
    "Mussafah M3",
    "Mussafah M32",
    "Mussafah M33",
    "Mussafah M34",
    "Mussafah M35",
    "Mussafah M36",
    "Mussafah M37",
    "Mussafah M38",
    "Mussafah M39",
    "Mussafah M4",
    "Mussafah M40",
    "Mussafah M41",
    "Mussafah M42",
    "Mussafah M43",
    "Mussafah M44",
    "Mussafah M45",
    "Mussafah M46",
    "Mussafah M5",
    "Mussafah M6",
    "Mussafah M7",
    "Mussafah M8",
    "Mussafah M9",
    "Mussafah MN 1",
    "Mussafah MN 2",
    "Mussafah MN 3",
    "Mussafah MN 4",
    "Mussafah MN5",
    "Mussafah MN6",
    "Mussafah MW1",
    "Mussafah MW2",
    "Mussafah MW3",
    "Mussafah MW4",
    "Mussafah MW5",
    "Mussafah Shabyah ME 1",
    "Mussafah Shabyah ME 10",
    "Mussafah Shabyah ME 11",
    "Mussafah Shabyah ME 12",
    "Mussafah Shabyah ME 13",
    "Mussafah Shabyah ME 14",
    "Mussafah Shabyah ME 15",
    "Mussafah Shabyah ME 16",
    "Mussafah Shabyah ME 2",
    "Mussafah Shabyah ME 3",
    "Mussafah Shabyah ME 4",
    "Mussafah Shabyah ME 5",
    "Mussafah Shabyah ME 6",
    "Mussafah Shabyah ME 7",
    "Mussafah Shabyah ME 8",
    "Mussafah Shabyah Me9",
    "Navy Gate- Al Zahiyah E17",
    "New Abu Dhabi Airport",
    "New Al Bahia",
    "New Al Falah",
    "New Al Nahda",
    "New Shahama",
    "Old Mushrif",
    "Police Officer Complex",
    "Presidential flight-Royal Jet",
    "Qasr Al Bahr",
    "Qasr Al Shatie Area",
    "Ras Al Khaimah",
    "Rowdhat Abu Dhabi",
    "Ruwais Residntial Area",
    "Sas Al Nakhl - Umm Al Nar",
    "Sas Al Nakhl Village",
    "Sheikh Zayed Cricket Stadium",
    "Sheikh Zayed Grand Mosque",
    "Tourist Club - Al Zahiyah E12",
    "Tourist Club - Al Zahiyah E13",
    "Tourist Club - Al Zahiyah E14",
    "Tourist Club - Al Zahiyah E15",
    "Tourist Club - Al Zahiyah E16",
    "Tourist Club - Al Zahiyah E16_01",
    "Tourist Club - Al Zahiyah E16_02",
    "World Trade Center - Al Danah E2",
    "Yas Island",
    "Zayani - Al Markaziyah E9 - 02 ",
    "Zayed Port",
    "Zayed Sports City",
    "Zayed University",
  ],
  [UAE_CITIES[1]]: [
    "Ajman Corniche",
    "Ajman Free Zone",
    "Ajman Industrial 1",
    "Ajman Industrial 2",
    "Ajman University",
    "Al Bustan - Liwara 1",
    "Al Bustan - Liwara 2",
    "Al Hamidiya 1",
    "Al Hamidiya 2",
    "Al Houmah",
    "Al Jurf 1",
    "Al Jurf 2",
    "Al Jurf Industrial 1",
    "Al Jurf Industrial 2",
    "Al Jurf Industrial 3",
    "Al Muaihat 1",
    "Al Muaihat 2",
    "Al Muaihat 3",
    "Al Nakhil 1 - Al Owan",
    "Al Nakhil 2 - Al Owan",
    "Al Nuaimia 2",
    "Al Nuaimia 3",
    "Al Raqaib 1",
    "Al Raqaib 2",
    "Al Rashidiya 1",
    "Al Rashidiya 2",
    "Al Rashidiya 3 - Al Sawan",
    "Al Rawda 1-Al Zahra",
    "Al Rawda 2-Al Zahra",
    "Al Rawda 3-Al Zahra",
    "Al Rumailah 1 - Karama",
    "Al Rumailah 2",
    "Al Rumailah 3",
    "Al Tallah -1",
    "Al Tallah -2",
    "Al Zorah",
    "Meshairef",
  ],
  [UAE_CITIES[2]]: [
    "Ain Al Fayda",
    "Al Agabiyya",
    "Al Ain University of Science and Technology",
    "Al Ain Zoo",
    "Al Bada",
    "Al Basra Park",
    "Al Bateen - Al Ghadeer",
    "Al Dhaher North",
    "Al Dhaher South",
    "Al Foah",
    "Al Grayyeh",
    "Al Hili Oasis",
    "Al Jahli",
    "Al Jimi - Al Ameriya",
    "Al Jimi Oasis",
    "Al Khalidiyyah",
    "Al Khrair",
    "Al Khrais - Al Jimi",
    "Al Mahatta - Al Towayya",
    "Al Maqam",
    "Al Maraegh - Al Jahli",
    "Al Markhaniya",
    "Al Markhaniya - Jizat Wraigah",
    "Al Markhaniya - Mreifia",
    "Al Markhaniya - Shakhbout",
    "Al Mashall - Al Sarooj ",
    "Al Masoudi",
    "Al Meryal - Al Khabisi",
    "Al Misbah",
    "Al Mnaizfah - Old Al Manaseer",
    "Al Mnaizlah - Falaj Hazzaa Waha",
    "Al Mraijeb",
    "Al Mraijeb Park",
    "Al Mutaredh",
    "Al Naglah",
    "Al Naseriyya",
    "Al Noor Complex",
    "Al Owainah - Falaj Hazzaa",
    "Al Qattara",
    "Al Qattara Club",
    "Al Ragayeb - Altowayya",
    "Al Ruwaikah - New Al Manaseer",
    "Al Salamat",
    "Al Saleel",
    "AL SALLAN",
    "Al Sarooj - Old",
    "Al Shabhat - Al Sarooj",
    "Al Shuaibah",
    "Al Sidrah",
    "Al Swuaifi",
    "Al Wadi Park",
    "Al Yahar",
    "Asharej - Al Dafeinah",
    "Batha Al Hayerr",
    "Bida Bin Ammar",
    "Bida Bint Saud",
    "Civic Center",
    "Companies Camp Mezyad",
    "Eidan Al Jareh",
    "Eidan Al Ridda - Al Towayya",
    "Gafat Al Nayyar",
    "Galaat Al Muwaiji",
    "Ghanima",
    "Green Mubazzarah",
    "Green Mubazzarah Villas",
    "Hai Al Madheef - Khalifa Street",
    "Hai Khalid - Al Muwaiji",
    "Hai Qesaidah - Main Street",
    "Hazzaa Bin Zayed Stadium",
    "Hili - Al Nayfa",
    "Hili Al Khaleef",
    "Hili Al Rumailah",
    "Hili Archaeological Park",
    "Hili Complex",
    "Hili Industrial Area",
    "Hili Shabeya Hamra",
    "Jafeer Obaid - Al Towayya",
    "Jahli Oasis - Al Ghail",
    "Jefeer Jadeed - Falaj Hazzaa ",
    "Maadhi - Al Towayya",
    "Magr Al Dhabi - Falaj Hazzaa Muroor ",
    "Majlood",
    "Manazef",
    "Mezyad",
    "Morabaa",
    "Mutawah",
    "Neima",
    "New Kuwaitat",
    "New Niyedat",
    "New Sarooj",
    "Oud Al Hassah",
    "Oud Al Touba",
    "OUD BIN SAG-HAN",
    "Police Colony",
    "Ramlat Aawad Shaheen - Al Jimi",
    "Ramlat Zakher",
    "Sabiya Khalifa",
    "Seeh Bin Ammar - Housing - Male",
    "Shabhanat Al Khabisi",
    "Shabhanat Asharej",
    "Shabyat Al Difaa",
    "Shareat Al Muwaiji",
    "Shiab Al Ashkhar",
    "Shibat Al Wutah",
    "Slemi - Al Jimi",
    "Tawam",
    "Towayya Garden",
    "Uae University",
    "Ugdat Al Mutawaa",
    "Um Al Sidir - Al Towayya",
    "Um Ghaffa",
    "Wadi Bin Shami - Al Towayya",
    "Zaafrana",
  ],
  [UAE_CITIES[3]]: [
    "Abu Baker Al Siddique",
    "Abu Hail",
    "Academic City",
    "Airport Terminal 1",
    "Airport Terminal 2",
    "Airport Terminal 3",
    "Al Badaa",
    "Al Badia",
    "Al Baraha",
    "Al Barari",
    "Al Barsha 1",
    "Al Barsha 2",
    "Al Barsha 3",
    "Al Barsha South",
    "Al Buteen",
    "Al Fahidi",
    "Al Furjan",
    "Al Ghadeer - Al Jarf",
    "Al Habtoor City",
    "Al Hamriya - Old Pakistani Consulate",
    "Al Hamriya Port",
    "Al Hudaiba",
    "Al Jaddaf",
    "Al Jaffiliya",
    "Al Karama",
    "Al Khabaisi",
    "Al Khail Gate",
    "Al Mamzar",
    "Al Manara",
    "Al Mankhool",
    "Al Mina",
    "Al Mizhar 1",
    "Al Mizhar 2",
    "Al Muntazah",
    "Al Muraqqabat",
    "Al Muteena - Al Muraqqabat St",
    "Al Nahda 1",
    "Al Nahda 2",
    "Al Qarhoud",
    "Al Quoz 1",
    "Al Quoz 2",
    "Al Quoz 3",
    "Al Quoz 4",
    "Al Quoz Industrial Area 1",
    "Al Quoz Industrial Area 2",
    "Al Quoz Industrial Area 3",
    "Al Quoz Industrial Area 4",
    "Al Qusais 1",
    "Al Qusais 2",
    "Al Qusais 3",
    "Al Qusais Industrial 1",
    "Al Qusais Industrial 2",
    "Al Qusais Industrial 3",
    "Al Qusais Industrial 4",
    "Al Qusais Industrial 5",
    "Al Raffa",
    "Al Ras",
    "Al Rashidiya",
    "Al Riqqa",
    "Al Sabkha",
    "Al Safa 1",
    "Al Safa 2",
    "Al Satwa",
    "Al Shaab Colony",
    "Al Shindagha",
    "Al Souq Al Kabeer",
    "Al Sufouh 1",
    "Al Sufouh 2",
    "Al Twar 1",
    "Al Twar 2",
    "Al Twar 3",
    "Al Waha",
    "Al Waheda",
    "Al Warqa 1",
    "Al Warqa 2",
    "Al Warqa 3",
    "Al Warqa 4",
    "Al Warqa 5",
    "Al Wasl",
    "Al Wasl Club",
    "Arabian Ranches",
    "Arabian Ranches 2",
    "Arjan Dubailand",
    "Ayal Nasir",
    "Badrah Residence",
    "Baniyas Square - Nasser Square",
    "Barsha Heights - TECOM",
    "Blue Water Islands",
    "Burj Residences Tower 1-9",
    "Burj View",
    "Business Bay",
    "Cargo Village",
    "Cedre Villas",
    "Cherrywoods",
    "City Of Arabia",
    "City Walk",
    "Corniche Deira",
    "Corniche Naif",
    "DAMAC HILLS",
    "Daria Island",
    "Desert Springs Village",
    "Dhow Wharfage",
    "Difc",
    "DIP - Dubai Investments Park 1",
    "DIP - Dubai Investments Park 2",
    "Discovery Gardens",
    "Dmcc Enterprise Zone",
    "Downtown Burj Khalifa",
    "Downtown Jabel Ali",
    "Dragon Mart",
    "Dubai Airport Free Zone",
    "Dubai Creek Golf Club",
    "Dubai Creek Harbour",
    "Dubai Creek Park",
    "Dubai Design District",
    "Dubai Festival City",
    "Dubai Healthcare City",
    "DUBAI HILLS",
    "Dubai Land Residence Complex",
    "Dubai Lifestyle City",
    "Dubai Marina",
    "Dubai Maritime City",
    "Dubai Miracle Garden",
    "Dubai Outsource Zone",
    "Dubai Pearl",
    "Dubai Production City",
    "Dubai Safari Park",
    "Dubai South",
    "Dubai Waterfront",
    "Dubal Main Gate",
    "Dubal South Gate",
    "Emaar Beachfront",
    "Emirates Golf Club",
    "Emirates Hills",
    "Emmar Square Blg 1-6",
    "Falcon City Of Wonders",
    "Festival Plaza",
    "Garden View Villas",
    "Gardenia Townhomes",
    "Golf City",
    "Green Community Motor City",
    "Green Community Village",
    "Greens",
    "Hamdan Area",
    "Hor Al Anz",
    "Hor Al Anz East",
    "Ibn Batutta Mall",
    "Impz",
    "International City",
    "International City Phase(2)",
    "Internet City",
    "Jafza-Jebel Ali Free Zone",
    "Jbr",
    "Jebel Ali",
    "Jebel Ali Freezone Extension-South",
    "Jebel Ali Industrial",
    "Jebel Ali Port - Mina Jebel Ali",
    "Jebel Ali Shooting Club",
    "Jebel Ali Technology Park",
    "Jebel Ali Village",
    "Jewel Of The Creek",
    "Jewellery & Gemplex",
    "Jlt-Jumeirah Lakes Towers",
    "Jumeira 1",
    "Jumeira 2",
    "Jumeira 3",
    "Jumeirah Golf Estates",
    "Jumeirah Heights",
    "Jumeirah Islands",
    "Jumeirah Park",
    "Jumeirah Village Circle",
    "Jumeirah Village Triangle",
    "Kaman 1-6",
    "Knowledge Village",
    "La Mer",
    "Layan",
    "LIWAN",
    "MAJAN",
    "Marsa Al Khor",
    "Meadows 1",
    "Meadows 2",
    "Meadows 3",
    "Meadows 4",
    "Meadows 5",
    "Meadows 6",
    "Meadows 7",
    "Meadows 8",
    "Meadows 9",
    "Media City",
    "Meena Bazaar",
    "Meska 1-6",
    "Meydan Grand Views",
    "Meydan Heights",
    "Meydan Polo Townhouses",
    "Millennium Estates Meydan",
    "Mira Community",
    "Mira Oasis",
    "Mirdif",
    "Motor City",
    "Mudon",
    "Muhaisnah 1",
    "Muhaisnah 3",
    "Muhaisnah 4",
    "Mushrif Park",
    "Mushrif Village",
    "Nad Al Sheba",
    "Nad Shamma",
    "Nadd Al Hamar",
    "Naif",
    "Oud Al Muteena 1",
    "Oud Al Muteena 2",
    "Oud Al Muteena 3",
    "Oud Metha",
    "Palm Jumeirah",
    "Pearl Jumeirah",
    "Port Rashid",
    "Port Saeed",
    "Qarhoud Village",
    "Ras Al Khor",
    "Ras Al Khor Industrial 1",
    "Ras Al Khor Industrial 2",
    "Ras Al Khor Industrial 3",
    "Rehan Blg 1-8",
    "Remraam",
    "Riggat Al Buteen",
    "Riverside",
    "Safa Park",
    "Salahuddin",
    "Semmer Villas",
    "Shabiath Kartoon",
    "Silicon Oasis",
    "Silicon Oasis HQ Building",
    "Silicon Oasis Industrial",
    "South Ridge Towers",
    "Sports City",
    "Springs 1",
    "Springs 10",
    "Springs 11",
    "Springs 12",
    "Springs 14",
    "Springs 15",
    "Springs 2",
    "Springs 3",
    "Springs 4",
    "Springs 5",
    "Springs 6",
    "Springs 7",
    "Springs 9",
    "Springs Town Center",
    "Studio City",
    "The Gardens",
    "The Lakes",
    "The Old Town",
    "The Polo Residence",
    "The Sustainable City",
    "The Villa",
    "Tilal Al Ghaf Dubai",
    "Town Square Dubai",
    "Trade Center 1",
    "Trade Center 2",
    "Umm Al Sheif",
    "Umm Hurair 1 - Consulate Area",
    "Umm Hurair 2",
    "Umm Ramool",
    "Umm Suqeim 1",
    "Umm Suqeim 2",
    "Umm Suqeim 3",
    "Uptown Dubai",
    "Vegetable Market",
    "Wafi City",
    "Warsan 2",
    "Warsan 3",
    "Yansoon 1-9",
    "Zaabeel 1",
    "Zaabeel 2",
    "Zaabeel Park (Gate 1-4)-Al Kifaf",
    "Zaabeel Park (Gate) 5-6",
    "Zafran Blg 1-8",
    "ZANGABEEL 1-6",
  ],
  [UAE_CITIES[4]]: [
    "Al Aud",
    "Al Bithnah",
    "Al Farfar",
    "Al Faseel",
    "Al Gurfa",
    "Al Hayl",
    "Al Itihad",
    "Al Mahatta",
    "Al Nagla",
    "Al Owaid",
    "Al Qurayya",
    "Al Sharia",
    "Anajaimat",
    "Corniche Fujairah",
    "Fujairah Airport",
    "Fujairah Free Zone",
    "Fujairah Port",
    "Haleefath",
    "Madhab",
    "Merashid",
    "Old Fujairah City",
    "Rughaylat",
    "Safad",
    "Sakamkam",
    "Seh Al Rahi - Industrial Area",
    "Sixteen Bldg Area",
    "Suheila",
    "Town Center",
  ],
  [UAE_CITIES[5]]: [
    "Agula",
    "Al Aud",
    "Al Baraha - Kalba",
    "Al Bateen - Kalba",
    "Al Bridi - Kalba",
    "Al Ghail",
    "Al Khuwair",
    "Al Mafraq - Kalba",
    "Al Mahatta - Kalba",
    "Al Musalla - Kalba",
    "Al Qalaa",
    "Al Saf",
    "Al Sidra",
    "Aldhyat",
    "Corniche Kalba",
    "Hitteen",
    "Industrial Area - Kalba",
    "Kalba Waterfront",
    "Khor Kalba",
    "Qadisiya - Kalba",
    "Sharjah University - Kalba",
    "Sour Kalba",
    "Suheila",
    "Tarif",
  ],
  [UAE_CITIES[6]]: [
    "Al Bidya",
    "Al Bridi",
    "Al Haray",
    "Al Khaledya Khorfakkan",
    "Al Mudaifi",
    "Al Musalla",
    "Hatin",
    "Hawami",
    "Hayawa",
    "Industrial Area",
    "Khorfakkan Beach",
    "Khorfakkan Port",
    "Luluyah - Al LuoloAya",
    "Qadisiya",
    "Shabiya",
    "Yarmouk",
    "Zubara",
  ],
  [UAE_CITIES[7]]: [
    "Al Darbijaniyah",
    "Al Dhait North",
    "Al Dhait South",
    "Al Filayah",
    "Al Ghubb",
    "Al Hamra",
    "Al Hamra Village",
    "Al Hudaibah",
    "Al Jazirah Al Hamra",
    "Al Kharran",
    "Al Mairid",
    "Al Mamourah",
    "Al Mataf",
    "Al Nadiyah",
    "Al Nakheel",
    "Al Nudood",
    "Al Qasimi Corniche",
    "Al Qawasim Corniche",
    "Al Qurm",
    "Al Qusaidat",
    "Al Rams",
    "Al Riffa",
    "Al Sall - Jolan",
    "Al Seer",
    "Al Shabiya",
    "Al Sharisha",
    "Al Soor - RAK",
    "Al Turfa",
    "Al Uraibi",
    "Aurak",
    "Dafan Al Khor",
    "Dafan Al Nakheel",
    "Dahan",
    "Khuzam",
    "Mina Al Arab",
    "Rak Club",
    "Ras Al Selaab",
    "Seih Al Ghubb",
    "Seih Al Hudaibah",
    "Seih Al Uraibi",
    "Seih Shihyar",
    "Sidroh",
    "Suhaim",
    "Wadi Ammar",
  ],
  [UAE_CITIES[8]]: [
    "Abu Shagara",
    "Al Abar- Halwan",
    "Al Azra",
    "Al Badei",
    "Al Darari",
    "Al Falah Camp",
    "Al Falaj",
    "Al Fayha",
    "Al Fisht - Al Heerah",
    "Al Ghafia",
    "Al Ghubaiba",
    "Al Ghuwair - Rolla",
    "Al Goaz",
    "Al Hamriyah Free Zone",
    "Al Hazannah",
    "Al Jazzat - Al Riqa",
    "Al Jubail",
    "Al Jurainah 1",
    "Al Jurainah 2",
    "Al Jurainah 3",
    "Al Jurainah 4",
    "Al Khaledia",
    "Al Khan",
    "Al Khezamia",
    "Al Layyeh",
    "Al Mahatah - Al Qasimia 2",
    "Al Majaz 1",
    "Al Majaz 2",
    "Al Majaz 3",
    "Al Mamzar",
    "Al Manakh",
    "Al Mansoura",
    "Al Mareija",
    "Al Mirqab",
    "Al Mujarrah",
    "Al Muntazah",
    "Al Musalla",
    "Al Nabaah",
    "Al Nahda",
    "Al Nasserya",
    "Al Nekhailat",
    "Al Noof 1",
    "Al Noof 2",
    "Al Noof 3",
    "Al Noof 4",
    "Al Nud - Al Qasimia 1",
    "Al Qadisiya",
    "Al Qulayaah",
    "Al Ramaqiya",
    "Al Ramla",
    "Al Ramtha",
    "Al Rifaa",
    "Al Rifaah",
    "Al Sabkha - Umm Khanoor",
    "Al Seef",
    "Al Shahba",
    "Al Shuwaihean",
    "Al Soor",
    "Al Sweihat",
    "Al Taawun",
    "Al Talaa",
    "Al Tarfa - Al Tarfana",
    "Al Yarmook",
    "Al Yash",
    "Aushj",
    "Barashi",
    "Bu Daniq",
    "Bu Tina",
    "Central Souq",
    "Dasman",
    "Industrial Area 1",
    "Industrial Area 10",
    "Industrial Area 11",
    "Industrial Area 12",
    "Industrial Area 13",
    "Industrial Area 15",
    "Industrial Area 17",
    "Industrial Area 18",
    "Industrial Area 2",
    "Industrial Area 3",
    "Industrial Area 4",
    "Industrial Area 5",
    "Industrial Area 6",
    "Industrial Area 7",
    "Industrial Area 8",
    "Industrial Area 9",
    "Maysaloon",
    "Muwafjah",
    "Muwailih Commercial",
    "Qarayen 1",
    "Qarayen 2",
    "Qarayen 3",
    "Qarayen 4",
    "Qarayen 5",
    "Saif Zone",
    "Samnan",
    "Sharjah Airport",
    "Sharjah National Park",
    "Sharjah University",
    "Sharqan",
    "Souq Al Haraj For Cars",
    "Um Altaraffa",
  ],
  [UAE_CITIES[9]]: [
    "Al Aahad",
    "Al Abrab",
    "Al Abraq 1",
    "Al Dar Al Baida 1-Shaabiya Al Baida",
    "Al Dar Al Baida 2-Shaabiya Baida",
    "Al Haditha - Shaabiat Al Ezaa",
    "Al Hamriyah Town",
    "Al Hawiyah",
    "Al Humrah 1-Shaabiyat Eissa",
    "Al Humrah 2",
    "Al Humrah 3",
    "Al Humrah 4",
    "Al Khor",
    "Al Limghadar",
    "Al Madr 1",
    "Al Madr 2",
    "Al Madr 3",
    "Al Maidan-Shaabiyat Al Moror",
    "Al Maqtaa 1",
    "Al Raas",
    "Al Ramlah",
    "Al Raudah",
    "Al Riqqah",
    "Al Salamah",
    "Defence Camp - Green Belt",
    "Industrial Area",
    "Laatein 1",
    "Laatein 2",
    "Neefah",
    "Old Town - Old Shaabiya",
    "Umm Al Quwain Marina",
  ],
};

export const RS_PAYMENT_STATUS = [
  "Payment Received",
  "Payment transferred to property",
  "Payment accepted by property",
];
export const RS_PROPERTY_STATUS = [
  "Received By Landlord",
  "Transaction In-Process",
];
export const RS_PAYMENT_STATUS_UPDATE = ["Payment Initiated", "Paid"];
export const RS_PAYMENT_MODE = [
  { label: "Card", value: "card" },
  { label: "Cash on Delivery", value: "cod" },
  { label: "Cash", value: "cash" },
  { label: "Payment Link", value: "payment_link" },
  { label: "Bank", value: "bank" },
  { label: "ATM", value: "atm" },
  { label: "Wallet", value: "wallet" },
];

export const PAYMENT_STATUS = {
  received: "Payment Received",
  transferred: "Payment transferred to property",
  accepted: "Payment accepted by property",
};

export const PROCESSING_FEE_TYPES = ["Fixed", "Percentage"];

export const BANNER_LOCATION = [
  { label: "Home", value: "Home" },
  { label: "Renters Pay", value: "Renters Pay" },
];

export const BANNER_STATUS = [
  { label: "Active", value: "Active" },
  { label: "Hidden", value: "Hidden" },
];

export const PROPERTY_TYPE = [
  { title: "Apartment", label: "Apartment", value: "Apartment" },
  { title: "Villa", label: "Villa", value: "Villa" },
  { title: "Townhouse", label: "Townhouse", value: "Townhouse" },
  { title: "Penthouse", label: "Penthouse", value: "Penthouse" },
  { title: "Compound", label: "Compound", value: "Compound" },
  { title: "Duplex", label: "Duplex", value: "Duplex" },
  { title: "Office Space", label: "Office Space", value: "Office Space" },
  { title: "Retail", label: "Retail", value: "Retail" },
  { title: "Warehouse", label: "Warehouse", value: "Warehouse" },
  { title: "Shop", label: "Shop", value: "Shop" },
  { title: "Showroom", label: "Showroom", value: "Showroom" },
];

export const PROPERTY_SUBTYPE = [
  { label: "Studio", value: "Studio" },
  { label: "1 Bedroom", value: "1 Bedroom" },
  { label: "2 Bedroom", value: "2 Bedroom" },
  { label: "3 Bedroom", value: "3 Bedroom" },
  { label: "4+ Bedrooms", value: "4+ Bedrooms" },
];

export const INVOICE_TYPE_SELECT = [
  { label: "Rent", value: "rent" },
  { label: "Miscellaneous", value: "miscellaneous" },
  { label: "Commission", value: "commission" },
  { label: "Security Deposit", value: "security_deposit" },
  { label: "Services Fees", value: "service_fees" },
];

export const INVOICE_TYPE = {
  rent: "Rent Payment",
  miscellaneous: "Miscellaneous",
  commission: "Comission",
  service_fees: "Service Fees",
  security_deposit: "Security Deposit",
};

export const INVOICE_STATUS = [
  { label: "Due", value: "due" },
  { label: "Paid", value: "paid" },
  { label: "Void", value: "void" },
];

export const PAYMENT_TYPE = {
  "credit-card": "Credit Card",
  cash: "Cash",
  "bank-transfer": "Bank Transfer",
  tabby: "Tabby",
};

export const PAYMENT_TYPE_SELECT = [
  { label: "Credit Card", value: "credit-card" },
  { label: "Cash", value: "cash" },
  { label: "Bank Transfer", value: "bank-transfer" },
];

export const PAYMENT_TYPES = [
  { label: "Success", value: "success" },
  { label: "Failed", value: "failed" },
  { label: "Void", value: "void" },
];
